import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import './AnalysisPage.css';

export class AnalysisPage extends Component {
    static displayName = AnalysisPage.name;

    constructor(props) {
        super(props);
        this.state = {
            navigateinvreturn: false,
            wasteItemName: '',
            wasteQuantity: 0,
            wasteReason: '',
            wasteEmployee: '',
            wasteManager: '',
            wasteDate: '',
            wasteRecords: [],
            startDate: 0,
            endDate: 0,
            items: [],
            itemName: '',
            units: 0,
            amount: 0,
            costPerUnit: 0,
            totalCost: 0,
            userEmail: '',
            UpdatedDate:'',
            BusinessPlan: '',
            userID: '',
            itemssnap:[],
            isAuthenticated: true,
            error: null,
            //wasteChartData: [],
            //wastereasonChartData: [],
            //inventoryChartData: [],
            //showSTDChart: false,
            debounceTimeout: null,
            oldReport: 'For accurate report a month of use is need\nThank you for being appericate your data check make sure it has enough...',
            newReport: 'For accurate report a month of use is need\nThank you for being appericate your data check make sure it has enough...',
            clicksLeft: 2,  // Initial value for the clicks left
            lastClicked: null,

        };
    }

    async componentDidMount() {
        const token = localStorage.getItem('token');
        const lastClicked = localStorage.getItem('lastClicked');
        const clicksLeft = parseInt(localStorage.getItem('clicksLeft')) || 2;
        if (!token) {
            this.setState({ isAuthenticated: false });
            return;
        }
        if (lastClicked && this.isSameWeek(lastClicked)) {
            this.setState({ clicksLeft });
        } else {
            // Reset the click count if the week has changed
            this.resetClickCount();
        }
        
        await this.fetchUserDetails(token);
        //await this.fetchInventoryItems(token);
        //await this.fetchRecords(token);

    }
    isSameWeek(lastClicked) {
        const now = new Date();
        const lastClickDate = new Date(lastClicked);
        const diff = now - lastClickDate;
        const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;  // 1 week in milliseconds
        return diff < oneWeekInMs;
    }

    fetchUserDetails = async (token) => {
        try {
            const response = await fetch('/user/userdetails', {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user details');
            }

            const user = await response.json();
            this.setState({
                userEmail: user.email,
                BusinessPlan: user.businessPlan,
                userID: user.UserId,
                isAuthenticated: true,
            });
        } catch (error) {
            console.error(error);
            this.setState({ isAuthenticated: false });
        }
    };

    
    handleDateChange = async (e) => {
        // Update state with the new value (startDate or endDate)
        this.setState({ [e.target.name]: e.target.value }, async () => {
            const { startDate, endDate } = this.state;

            // Check if both startDate and endDate are available
            if (startDate && endDate) {
                try {
                    const token = localStorage.getItem('token');
                    const query = new URLSearchParams({
                        startDate: startDate,
                        endDate: endDate
                    }).toString();

                    // Fetch from /foodwaste/snapshotrecords
                    const foodWasteResponse = await fetch(`/foodwaste/snapshotrecords?${query}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    // Fetch from /inventory/snapshotitems
                    const inventoryResponse = await fetch(`/inventory/snapshotitems?${query}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    // Handle both responses
                    if (foodWasteResponse.ok && inventoryResponse.ok) {
                        const foodWasteRecords = await foodWasteResponse.json();
                        const inventoryRecords = await inventoryResponse.json();

                        // Combine both record sets (assuming you want them together)
                        

                        // Update state with combined sorted records
                        this.setState({ wasteRecords: foodWasteRecords, itemssnap:inventoryRecords });
                    } else {
                        // Handle errors for both fetches
                        const foodWasteError = await foodWasteResponse.text();
                        const inventoryError = await inventoryResponse.text();
                        this.setState({
                            error: `Failed to fetch records: ${foodWasteError || 'Food waste error'}, ${inventoryError || 'Inventory error'}`
                        });
                    }
                } catch (error) {
                    console.error('Error:', error);
                    this.setState({ error: 'An unexpected error occurred' });
                }
            } else {
                console.error('Start Date and End Date must be provided.');
                this.setState({ error: 'Start Date and End Date must be provided.' });
            }
        });
    };
    updateDateRange = (records) => {
        const currentDate = new Date();
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

        // Get the last day of the current month
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);  

        this.setState({
            wasteRecords: records,
            startDate: firstDay.toISOString().split('T')[0],
            endDate: lastDay.toISOString().split('T')[0]
        }, () => {
            this.handleDateChange({ target: { name: 'startDate', value: this.state.startDate } });
            this.handleDateChange({ target: { name: 'endDate', value: this.state.endDate } });
            
        });
    };
    toggleChartType = () => {
        // Clear the previous timeout if it exists
        this.setState(prevState => ({ showSTDChart: !prevState.showSTDChart }));
        }

        
      

    handleinvreturn = () => {
        this.setState({ navigateinvreturn: true });
    };

   
    
    handleSalesAnalysis = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/SalesAnalysis', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch reports'); // Handle errors
            }

            const data = await response.json();

            // Convert Base64 to Blob and create download links
            const createDownloadLink = (base64Data, fileName) => {
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                return { url, fileName };
            };

            const newReport = data.newestReport
                ? createDownloadLink(data.newestReport, "Newest_Report.pdf")
                : "For accurate report, a month of use is needed.\nThank you for your appreciation! Please ensure sufficient data.";

            const oldReport = data.secondNewestReport
                ? createDownloadLink(data.secondNewestReport, "Second_Newest_Report.pdf")
                : "For accurate report, a month of use is needed.\nThank you for your appreciation! Please ensure sufficient data.";

            this.setState({ oldReport, newReport });

        } catch (error) {
            console.error('Error fetching reports:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    };

    handleCostManagement = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/CostManagement', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch old report'); // Handle errors
            }

            const oldReportData = await response.json(); // Parse the JSON response

            // Update state with old report data
            this.setState({
                oldReport: oldReportData // Store old report data in state
            });
        } catch (error) {
            console.error('Error fetching old report:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    };
    handleInventoryManagement = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/InventoryManagement', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch old report'); // Handle errors
            }

            const data = await response.json();

            // Convert Base64 to Blob and create download links
            const createDownloadLink = (base64Data, fileName) => {
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                return { url, fileName };
            };

            const newReport = data.newestReport
                ? createDownloadLink(data.newestReport, "Newest_Report.pdf")
                : "For accurate report, a month of use is needed.\nThank you for your appreciation! Please ensure sufficient data.";

            const oldReport = data.secondNewestReport
                ? createDownloadLink(data.secondNewestReport, "Second_Newest_Report.pdf")
                : "For accurate report, a month of use is needed.\nThank you for your appreciation! Please ensure sufficient data.";

            this.setState({ oldReport, newReport });

        } catch (error) {
            console.error('Error fetching reports:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    };
    handleProfitabilityAnalysis = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/ProfitabilityAnalysis', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch old report'); // Handle errors
            }

            const oldReportData = await response.json(); // Parse the JSON response

            // Update state with old report data
            this.setState({
                oldReport: oldReportData // Store old report data in state
            });
        } catch (error) {
            console.error('Error fetching old report:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    };
    handleTrendAnalysis = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/TrendAnalysis', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch old report'); // Handle errors
            }

            const oldReportData = await response.json(); // Parse the JSON response

            // Update state with old report data
            this.setState({
                oldReport: oldReportData // Store old report data in state
            });
        } catch (error) {
            console.error('Error fetching old report:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    };
    handleWasteAnalysis = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/WasteAnalysis', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch waste analysis report'); // Handle errors
            }

            const data = await response.json();

            // Convert Base64 to Blob and create download links
            const createDownloadLink = (base64Data, fileName) => {
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                return { url, fileName };
            };

            const newReport = data.newestReport
                ? createDownloadLink(data.newestReport, "Newest_Waste_Report.pdf")
                : "For accurate report, a month of use is needed.\nThank you for your appreciation! Please ensure sufficient data.";

            const oldReport = data.secondNewestReport
                ? createDownloadLink(data.secondNewestReport, "Second_Newest_Waste_Report.pdf")
                : "For accurate report, a month of use is needed.\nThank you for your appreciation! Please ensure sufficient data.";

            this.setState({ oldReport, newReport });

        } catch (error) {
            console.error('Error fetching waste analysis reports:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    };

    handleReasonforWaste = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/ReasonforWaste', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch reason for waste report');
            }

            const data = await response.json();

            // Convert Base64 to Blob and create download links
            const createDownloadLink = (base64Data, fileName) => {
                if (!base64Data) {
                    return "For accurate report, a month of use is needed.\nThank you for your appreciation! Please ensure sufficient data.";
                }
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                return { url, fileName };
            };

            // Generate download links for newest and second newest reason-for-waste reports
            const newReasonReport = data.newestReport
                ? createDownloadLink(data.newestReport, "Newest_Reason_for_Waste_Report.pdf")
                : "For accurate report, a month of use is needed.\nThank you for your appreciation! Please ensure sufficient data.";

            const oldReasonReport = data.secondNewestReport
                ? createDownloadLink(data.secondNewestReport, "Second_Newest_Reason_for_Waste_Report.pdf")
                : "For accurate report, a month of use is needed.\nThank you for your appreciation! Please ensure sufficient data.";

            this.setState({ newReasonReport, oldReasonReport });

        } catch (error) {
            console.error('Error fetching reason for waste reports:', error);
            this.setState({ error: error.message });
        }
    };
    
    handleEmployeeandManagerAccountability = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/EmployeeandManagerAccountability', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch old report'); // Handle errors
            }

            const oldReportData = await response.json(); // Parse the JSON response

            // Update state with old report data
            this.setState({
                oldReport: oldReportData // Store old report data in state
            });
        } catch (error) {
            console.error('Error fetching old report:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    };
    resetClickCount() {
        const now = new Date();
        localStorage.setItem('clicksLeft', 2);  // Reset to 2 clicks
        localStorage.setItem('lastClicked', now.toISOString());
        this.setState({ clicksLeft: 2 });
    }
    handleGenerateNewReport = async () => {
        const { itemssnap, wasteRecords, userID, clicksLeft } = this.state; // Destructure existing state

        // Log or validate itemssnap to ensure it's an array with data
        if (itemssnap.length < 100) {
            alert('You need more data (at least 100 items) to generate the report.');
            return;
        }
        if (clicksLeft <= 0) {
            alert('You have reached the maximum number of clicks for this week.');
            return;
        }
        this.setState(prevState => {
            const newClicksLeft = prevState.clicksLeft - 1;
            return { clicksLeft: newClicksLeft };
        });
        localStorage.setItem('clicksLeft', this.state.clicksLeft - 1);
        localStorage.setItem('lastClicked', new Date().toISOString());

        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/generateNewReport', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    SnapshotItems: itemssnap,  // Ensure this is an array
                    WasteRecords: wasteRecords, // Ensure wasteRecords is properly formatted
                    UserID: userID,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to generate new report');
            }

            const newReportData = await response.json(); // Parse the JSON response
            this.setState({
                newReport: newReportData
            });
        } catch (error) {
            console.error('Error generating new report:', error);
            this.setState({ error: error.message });
        }
    }
    buildCharts = () => {
        const wasteData = this.state.wasteRecords.map(record => ({
            name: record.wasteItemName,
            value: record.wasteQuantity,
        }));

        const inventoryData = this.state.items.map(item => ({
            name: item.productName,
            value: item.units,
        }));

        this.setState({ wasteChartData: wasteData, inventoryChartData: inventoryData });
    };
    
    renderBasicBarChart(data) {
        if (data.length === 0) {
            return <p>No data available</p>;
        }

        return (
            <div className="analysis-chart-container">
                {data.map(item => (
                    <div key={item.name} className="analysis-chart-bar">
                        <span className="analysis-chart-bar-label">{item.name}</span>
                        <div
                            className="analysis-chart-bar-fill"
                            style={{ width: `${(item.value / 100) * 100}%` }}
                        />
                        <span className="analysis-chart-bar-value">{item.value}</span>
                    </div>
                ))}
            </div>
        );
    }

    renderSTDBarChart(data) {
        if (data.length === 0) {
            return <p>No data available</p>;
        }

        return (
            <div className="analysis-chart-container">
                {data.map(item => (
                    <div key={item.name} className="analysis-chart-bar">
                        <span className="analysis-chart-bar-label">{item.name}</span>
                        <div
                            className="analysis-chart-bar-fill"
                            style={{ width: `${(item.value / 100) * 100}%` }}
                        />
                        <span className="analysis-chart-bar-value">{item.value}</span>
                    </div>
                ))}
            </div>
        );
    }

    render() {
        const { navigateinvreturn, clicksLeft  /*wasteChartData, wastereasonChartData, inventoryChartData, showSTDChart,*/  } = this.state;

        if (navigateinvreturn) {
            return <Navigate to="/dashboard" />;
        }

        return (
            <div>
                <h1 className="analysis-title">Analysis</h1>
                <button className="analysis-button" onClick={this.handleinvreturn}>Return</button>
                <div className="analysis-form-group">
                    <label htmlFor="startDate" className="analysis-label">Start Date:</label>
                    <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        className="analysis-input-date"
                        value={this.state.startDate}
                        onChange={this.handleDateChange}
                    />
                </div>
                <div className="analysis-form-group">
                    <label htmlFor="endDate" className="analysis-label">End Date:</label>
                    <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        className="analysis-input-date"
                        value={this.state.endDate}
                        onChange={this.handleDateChange}
                    />
                </div>
               
                <div className="button-container">

                    <button className="analysis-button" onClick={this.handleGenerateNewReport}>
                        Generate New Report  {clicksLeft} click{clicksLeft === 1 ? '' : 's'} left this week.
                    </button>
                    
                </div>
                
                <div className="button-container">
                <button className="analysis-button" onClick={this.handleSalesAnalysis}>AI Cost-Saving Reports </button>
                <button className="analysis-button" onClick={this.handleInventoryManagement}>Inventory Management</button>
                {/*<button className="analysis-button" onClick={this.handleProfitabilityAnalysis}>Profitability Analysis</button>*/}
                
                </div>
                <div className="button-container">
                    
                   {/* <button className="analysis-button" onClick={this.handleTrendAnalysis}>Trend Analysis</button>*/}
                    <button className="analysis-button" onClick={this.handleWasteAnalysis}>Waste Analysis</button>
                    <button className="analysis-button" onClick={this.handleReasonforWaste}>Reason for Waste</button>
                    {/*<button className="analysis-button" onClick={this.handleEmployeeandManagerAccountability}>Employee and Manager Accountability</button>*/}
                </div>
                <div className="report-container">
                    <div className="report old-report">
                        <h2>Old Report</h2>
                        <div>
                            <a href={this.state.oldReport.url} download={this.state.oldReport.fileName}>
                                Download Second Newest Report
                            </a>
                        </div>
                    </div>
                    <div className="report new-report">
                        <h2>New Report</h2>
                        <div>
                            <a href={this.state.newReport.url} download={this.state.newReport.fileName}>
                                Download Newest Report
                            </a>
                        </div>
                    </div>
                </div>

               
            </div>
        );
    }
}