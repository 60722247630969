import React, { Component } from 'react';
import { Navigate } from 'react-router-dom'; // Import Navigate for redirection
import './Login.css';

export class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            resetCode: '',
            password: '',
            error: '',
            message: '',
            isAuthenticated: false, // Manage redirection
            token: '',
            showModal: false,
            newPassword: '',
            confirmPassword: '',
            emailValid: false,  // For email validation
            resetCodeValid: false, // For reset code validation
            resetCodeSent: false,
            resetCofirmCode: '', 
            
        };
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        /*const saltRounds = 10;*/

        //try {
        //    // Hash the password
        //    const hashpassword = await new Promise((resolve, reject) => {
        //        bcrypt.hash(password, saltRounds, (err, hash) => {
        //            if (err) {
        //                reject(err);
        //            } else {
        //                resolve(hash);
        //            }
        //        });
        //    });
        try {
            const response = await fetch('/account/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password })
            });

            // Log response status and text for debugging
            /* console.log('Response Status:', response.status);*/
            const responseText = await response.text();
            /*console.log('Response Text:', responseText);*/

            if (!response.ok) {
                this.setState({ error: responseText || 'Invalid credentials' });
                return;
            }

            // Attempt to parse JSON response
            try {
                const result = JSON.parse(responseText);
                /*console.log('Login Result:', result); // Log to check*/
                const { token } = result;

                // Save authentication status and token
                if (token) {
                    localStorage.setItem('auth', true);
                    localStorage.setItem('token', token);
                    this.setState({ isAuthenticated: true, token }); // Update state to trigger redirect
                } else {
                    this.setState({ error: 'No token received' });
                }
            } catch (jsonError) {
                console.error('Error parsing JSON:', jsonError);
                this.setState({ error: 'Failed to parse server response' });
            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ error: 'An unexpected error occurred' });
        }
    };
    handleSendResetCode = async (e) => {
        e.preventDefault();
        const { email } = this.state;

        if (!this.state.emailValid) {
            this.setState({ error: 'Please enter a valid email.' });
            return;
        }

        try {
            // Step 1: Send the email to the backend to generate and send reset code
            const emailResponse = await fetch('/account/sendResetCode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!emailResponse.ok) {
                const errorText = await emailResponse.text();
                this.setState({ error: errorText || 'Failed to send reset code.' });
                return;
            }

            // Step 2: Get the reset code from the backend response
            const emailData = await emailResponse.json();
            const resetCode = emailData.resetCode;  // This is the reset code returned by the backend

            console.log('resetCode from backend:', resetCode); // Debugging

            // Step 3: Store the reset code in the state
            this.setState({
                message: 'Reset code sent to your email. Please check your inbox.',
                resetCodeSent: true,  // Indicate that the reset code is sent
                resetCofirmCode: resetCode, // Store the reset code here
            }, () => {
                // After state is updated, log the resetCofirmcode
                console.log('resetCofirmcode after state update:', this.state.resetCofirmCode);
            });
            
        } catch (error) {
            console.error('Error:', error);
            this.setState({ error: 'An unexpected error occurred' });
        }
    };
    handleForgotPassword = async (e) => {
        e.preventDefault();
        const { email, resetCofirmCode, newPassword, resetCode } = this.state;

        console.log('resetCofirmcode during forgot password:', this.state.resetCofirmCode); // Debugging

        // Step 1: Check if resetCofirmcode is available
        if (!resetCofirmCode) {
            this.setState({ error: 'Reset code not available. Please request the code first.' });
            return;
        }

        // Step 2: Check if the reset code entered by the user matches the one sent
        if (resetCode !== resetCofirmCode) {
            this.setState({ error: 'Invalid reset code. Please try again.' });
            return;
        }

        // Step 3: Validate password length or other conditions
        if (!newPassword || newPassword.length < 6) {
            this.setState({ error: 'Password must be at least 6 characters long.' });
            return;
        }

        try {
            // Step 4: Send the password reset request to the backend
            const passwordResponse = await fetch('/account/forgotpassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, newPassword, resetCode }),  // Send email, new password, and reset code to backend
            });

            if (!passwordResponse.ok) {
                const errorText = await passwordResponse.text();
                this.setState({ error: errorText || 'Failed to reset password.' });
                return;
            }

            // Step 5: Handle success
            this.setState({ success: 'Password has been successfully reset.' });
        } catch (error) {
            console.error('Error:', error);
            this.setState({ error: 'An unexpected error occurred' });
        }
    };

    openModal = () => {
        this.setState({ showModal: true }); // Show modal when forgot password link is clicked
    };

    closeModal = () => {
        this.setState({ showModal: false }); // Close modal
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [e.target.name]: e.target.value,
        });
        this.setState({ [name]: value }, () => {
            // Validate email whenever it changes
            if (name === 'email') {
                this.validateEmail(value);
            }

            // Validate reset code whenever it changes
            if (name === 'resetCode') {
                this.validateResetCode(value);
            }
        });
    };
    validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;  // Simple email validation regex
        const emailValid = emailRegex.test(email);
        this.setState({ emailValid });
    };

    // Reset code validation function (check if it's a 6-digit number)
    validateResetCode = (code) => {
        const resetCodeValid = code.length === 6 && /^[0-9]+$/.test(code);  // Only 6-digit numeric code
        this.setState({ resetCodeValid });
    };

    render() {
        const { email, password, error, isAuthenticated, showModal, newPassword, message, emailValid, resetCodeValid, resetCodeSent, resetCode,success } = this.state;
        
        if (isAuthenticated) {
            return <Navigate to="/dashboard" />; // Redirect to InventorySystem component
        }
        //if (isAuthenticated) {
        //    return <Navigate to="/inventorysystem" />; // Redirect to InventorySystem component
        //}
        return (
            <div className="login-container">
                <h2 className="login-title">Login</h2>
                {error && <p className="login-error">{error}</p>}
                <form className="login-form" onSubmit={this.handleSubmit}>
                    <div className="Login-form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                            required
                            className="form-control"
                            placeholder="Enter your email"
                        />
                        {email && !emailValid && <p className="error-message">Please enter a valid email address.</p>}
                    </div>
                    <div className="Login-form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={this.handleChange}
                            required
                            className="form-control"
                        />
                    </div>
                    <button type="submit" className="login-button">Login</button>
                    <p className="forgot-password-link" onClick={this.openModal}>Forgot Password?</p>

                </form>
                {showModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <h2>Reset Your Password</h2>
                            <form onSubmit={this.handleForgotPassword}>
                                {/* Email Input */}
                                <div className="modal-form-group">
                                    <label htmlFor="email">Email:</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                        required
                                        className="form-control"
                                        placeholder="Enter your email"
                                    />
                                    {email && !emailValid && <p className="error-message">Please enter a valid email address.</p>}
                                </div>

                                {/* Send Reset Code Button (Visible after email is valid) */}
                                {!resetCodeSent && emailValid && (
                                    <button
                                        type="button"
                                        onClick={this.handleSendResetCode}
                                        className="reset-password-button"
                                    >
                                        Send Reset Code
                                    </button>
                                )}

                                {/* Success/Error Messages */}
                                {message && <p className="success-message">{message}</p>}
                                {error && <p className="error-message">{error}</p>}

                                {/* Reset Code Input (Visible after reset code is sent) */}
                                {resetCodeSent && (
                                    <div className="modal-form-group">
                                        <label htmlFor="resetCode">Reset Code:</label>
                                        <input
                                            type="text"
                                            id="resetCode"
                                            name="resetCode"
                                            value={resetCode}
                                            onChange={this.handleChange}
                                            required
                                            className="form-control"
                                            placeholder="Enter reset code"
                                        />
                                        {resetCode && !resetCodeValid && <p className="error-message">Reset code must be a 6-digit number.</p>}
                                    </div>
                                )}

                                {/* New Password Input */}
                                {resetCodeSent && (
                                    <div className="modal-form-group">
                                        <label htmlFor="newPassword">New Password:</label>
                                        <input
                                            type="password"
                                            id="newPassword"
                                            name="newPassword"
                                            value={newPassword}
                                            onChange={this.handleChange}
                                            required
                                            className="form-control"
                                            placeholder="Enter new password"
                                        />
                                    </div>
                                )}

                                {/* Submit Button */}
                                {resetCodeSent && (
                                    <button
                                        type="submit"
                                        disabled={!resetCodeValid}
                                        className="reset-password-button"
                                    >
                                        Reset Password
                                    </button>
                                    
                                )}
                                {success && (
                                    <p>{success}</p> // Display the success message
                                )}
                            </form>
                            <button onClick={this.closeModal} className="close-modal-button">Close</button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}